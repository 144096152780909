import React from "react"
import { StaticQuery, graphql } from "gatsby"
import "./politica-horus.container.style.scss"

export default class PoliticaContainer extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className="about-container">
        <div className="about-container--wrapper">
          <div className="has-text-centered">
            <h1 className="titulo-about">Política de Pricvacidade</h1>
          </div>

          <div>
            <p className="politica__paragraph politica__section-title politica--bold-text">Esta Política de Privacidade tem como alvo os usuários do App Horus Leituras</p>
            <p className="politica__paragraph">Última versão editada no dia 26/05/2020.</p>
            <p className="politica__paragraph">Quando o usuário usa nossos serviços, confia a nós suas informações. Esta Política de Privacidade destina-se a ajudar o usuário a entender como abordamos suas informações pessoais.</p>
            <p className="politica__paragraph">Quando o usuário usa os nossos serviços, <span className="politica--bold-text">o usuário concorda com as práticas de privacidade e coleta, armazenamento e compartilhamento de dados conforme descrito nesta Política de Privacidade.</span></p>
            <p className="politica__paragraph">Nossa equipe de atendimento ao consumidor estará sempre disposta a esclarecer qualquer dúvida sobre nossa Política de Privacidade, através do canal “Contato” <a href="https://sflabs.com.br/contato/" target="_blank" rel="noopener noreferrer">(https://sflabs.com.br/contato/)</a>.</p>
          </div>

          <div>
            <div className="politica__section-title politica--bold-text">Introdução</div>
            <p className="politica__paragraph">O aplicativo é uma ferramenta de trabalho para a coleta de leituras de sensores, integrante do software Horus. Ele permite a união da informação da localização do sensor, do usuário que fez a leitura, do sensor lido e da leitura informada pelo usuário.</p>
            <p className="politica__paragraph">Nossos usuários são apontados por empresas que contratam uma licença do App Horus Leituras. Essas empresas, através do portal web, cadastram seus usuários informando nome, sobrenome, CPF e email, senha e permitem que eles acessam o Aplicativo e sincronizem dados com a plataforma.</p>
            <p className="politica__paragraph">Todas as informações coletadas são propriedade da empresa contratante da licença e estarão disponíveis para consulta. Exclusões do banco de dados podem ser solicitadas pela empresa contratante.</p>
            <p className="politica__paragraph">As informações coletadas são sigilosas e respeitam o acordo de confidencialidade firmado entre empresa a SF Labs e a empresa contratante da licença.</p>
            <p className="politica__paragraph">Caso não concorde com esta política de privacidade, não instale o aplicativo e entre em contato com a empresa contratante da licença.</p>
          </div>

          <div className="content">
            <ol className="is-upper-roman">
              <li className="politica__section-title politica--bold-text">Informações coletadas</li>
              <div className="content">
                <ol>
                  <li className="politica--bold-text">Informações que o usuário decide compartilhar conosco</li>
                  <div className="content">
                    <ul>
                      <li><span className="politica--bold-text">Informações referentes ao usuário: </span>Coletamos nome completo, CPF, CNPJ da empresa na qual o usuário trabalha, RG, data de nascimento, endereço de e-mail, número de telefone celular e endereço. Estes dados são imprescindíveis para o cadastro e acesso do usuário. Estes dados são informados diretamente pela empresa que contratou a licença do App Horus Leituras.</li>
                      <li>Quando o usuário entra em contato conosco de qualquer maneira, as conversas de e-mail, chat, mensagem e telefone poderão ser identificadas, armazenadas, identificadas, vinculadas ao cadastro do usuário e poderão ser solicitadas pelo usuário.</li>
                    </ul>
                  </div>
                  <li className="politica--bold-text">Informações que obtemos quando o usuário usa nossos serviços</li>
                  <div className="content">
                    <ul>
                      <li><span className="politica--bold-text">Informações do dispositivo: </span>Coletamos informações sobre as conexões de rede sem fio e móvel e bluetooth, para garantir a conexão do aplicativo à rede.</li>
                      <li><span className="politica--bold-text">Informações de conteúdo: </span>Coletamos informações que são criadas pelo usuário através dos nossos serviços, como o horário de utilização.</li>
                      <li><span className="politica--bold-text">Câmera: </span>Nosso aplicativo requer a obtenção do QR Code por meio da câmera do dispositivo celular ou tablet do usuário. O usuário não conseguirá enviar o QR Code a menos que possamos acessar a sua câmera.</li>
                      <li><span className="politica--bold-text">Informações sobre localização: </span>Quando o usuário utiliza o App, nós podemos coletar informações sobre a sua localização. Com o consentimento do usuário, nós também podemos coletar informações sobre a sua localização exata, usando métodos que incluem GPS, redes sem fio, torres de telefonia celular, pontos de acesso Wi-Fi e outros sensores, tais como giroscópios, acelerômetros e bússolas. Para tal, o usuário será solicitado quanto a permissão do uso e ao permitir que o celular utilize estas funções, estará concordando com a sua utilização. Lembrado que a coleta de dados de localização faz parte do serviço prestado a empresa que contrata a licença do “Horus Leituras”.</li>
                      <li><span className="politica--bold-text">Informações coletadas por cookies: Podemos usar cookies e outras tecnologias, como web beacons, armazenamento na web e identificadores de publicidade exclusivos, para coletar informações sobre sua atividade, navegador e dispositivo, para manter informações sobre o usuário. </span>A maioria dos navegadores é configurada, de maneira padrão, para aceitar cookies. Se o usuário preferir, geralmente é possível remover ou rejeitar os cookies do navegador através das configurações em seu navegador ou dispositivo. Ao desabilitar a opção de armazenamento de cookies em seu navegador, não será possível utilizar o nosso serviço em toda sua funcionalidade.</li>
                      <li><span className="politica--bold-text">Informações do log: </span>Coletamos informações de acesso quando o usuário usa nosso site ou aplicativo. Essas informações incluem, entre outras: detalhes sobre como o usuário utilizou nossos serviços, informações do dispositivo (tais como o tipo e o idioma do navegador), horários de acesso, páginas visualizadas, endereço IP, identificadores associados a cookies ou a outras tecnologias que podem identificar exclusivamente o dispositivo ou navegador e páginas que o usuário visitou antes ou depois de navegar para nosso site.</li>
                    </ul>
                  </div>
                  <li className="politica--bold-text">Informações que coletamos de terceiros</li>
                  <div className="content">
                    <ul>
                      <li>A SF Labs, suas subsidiárias ou empresas do grupo econômico, pode obter informações de quaisquer outras fontes de terceiros, e combiná-las com as informações que coletamos por meio de nossos serviços.</li>
                    </ul>
                  </div>
                </ol>
              </div>
              <li className="politica__section-title politica--bold-text">Informações Compartilhadas</li>
              <p className="politica__paragraph"> A SF Labs, suas subsidiárias ou empresas do grupo econômico, utilizará as informações apenas no âmbito de garantir o cumprimento do contrato estabelecido com a empresa que contratou a lincença do software Horus. Para tal ela poderá compartilhar informações sobre o usuário da seguinte maneira:</p>
              <div className="content">
                <ol>
                  <li><span className="politica--bold-text">Com a empresa que adquiriu a licença do software Horus vinculada ao uso do aplicativo pelo usuário: </span>Podemos compartilhar informações do usuário como o nome do usuário e CNPJ da empresa na qual trabalha, assim como o horário de utilização informações sobre localização. Todas as informações estarão disponíveis para a empresa que contratou a licença.</li>
                  <li><span className="politica--bold-text">Com nossas afiliadas: </span>Podemos compartilhar informações com entidades pertencentes à família de empresas da SF Labs.</li>
                  <li><span className="politica--bold-text">Com prestadores de serviços, vendedores e parceiros: </span>Podemos compartilhar informações sobre o usuário com provedores de serviço que realizam serviços em nosso nome, vendedores que fornecem bens através dos nossos serviços e parceiros de negócios que forneçam serviços e funcionalidade.</li>
                  <li>
                    <span className="politica--bold-text">Com terceiros por razões legais: </span>Podemos compartilhar informações sobre o usuário se acreditarmos, razoavelmente, que a divulgação destas informações seja necessária para:
                    <div className="content">
                      <ol>
                        <li>Cumprir com todo e qualquer procedimento legal válido, solicitação governamental ou leis, regras e/ou regulamentos aplicáveis.</li>
                        <li>Investigar, reparar ou punir eventuais violações dos Termos de Serviço.</li>
                        <li>Proteger os direitos, a propriedade e a segurança de nós mesmos, de nossos usuários ou de terceiros.</li>
                        <li>Detectar e resolver todas as questões relacionadas a fraudes e segurança.</li>
                      </ol>
                    </div>

                  </li>
                  <li><span className="politica--bold-text">Com terceiros como parte de uma fusão ou aquisição: </span>Se a SF Labs se envolver em uma fusão, venda de ativos, financiamento, liquidação ou falência, ou na aquisição de todos ou de alguma parte do nosso negócio para outra empresa, podemos compartilhar as informações do usuário com aquela empresa, antes e depois do fechamento da transação.</li>
                </ol>
              </div>
              <p className="politica__paragraph">Com as exceções requeridas por lei, nenhuma pessoa pode divulgar, ou expor propositadamente, senhas e/ou cookies gerados para identificar um usuário</p>
              <li className="politica__section-title politica--bold-text">Armazenamento de Informações</li>
              <div className="content">
                <ol>
                  <li>Os dados do usuário serão mantidos em local seguro e sigiloso e todas as informações coletadas serão utilizadas para o desenvolvimento dos nossos serviços</li>
                  <li>Por obrigação legal, devemos armazenar os registros de acesso a aplicações de internet pelo prazo de seis meses (art. 15 do Marco Civil da Internet, lei 12.955 de 23 de abril de 2014), podendo ser estendido, por determinação judicial. Manteremos as informações em local sigiloso e controlado, não a utilizando ou tornando pública, salvo com expresso consentimento do Usuário ou mediante determinação do Poder Judiciário.</li>
                  <li> Em caso de exclusão de conta, as informações do usuário serão mantidas por 30 dias para possível restauração do serviço e, após esse período, as informações do usuário poderão ser excluídas definitivamente dos nossos servidores.</li>
                </ol>
              </div>
              <li className="politica__section-title politica--bold-text">Conteúdo e integrações de terceiros</li>
              <p className="politica__paragraph">Os serviços prestados por nós podem incluir links de terceiros e resultados de pesquisas, integrações de terceiros ou oferecer um serviço de parceria entre marcas ou de marca de terceiros. Usando esses links, integrações de terceiros e serviços de parceria entre marcas ou de marca de terceiros, o usuário pode estar fornecendo informações (incluindo informações pessoais) diretamente ao terceiro, a nós ou a ambos.</p>
              <p className="politica__paragraph">Esta Política de Privacidade não se aplica a websites e aplicações fornecidas e controladas por terceiros, mesmo que estejam anunciados como publicidade em nosso website ou aplicativo. Nós não nos responsabilizamos pelas Políticas de Privacidade destas terceiras partes e recomendamos fortemente que o Usuário leia as políticas de privacidade a cada acesso a novo website ou aplicativo.</p>
              <li className="politica__section-title politica--bold-text">Controle do usuário sobre suas informações</li>
              <div className="content">
                <ol>
                  <li><span className="politica--bold-text">Atualizações: </span>O usuário, ao verificar informações inexatas ou incorretas sobre seu cadastro ou dados, poderá exigir a imediata correção e retificação, no prazo de 10 dias úteis a partir da comunicação (art. 43, §3º da Lei 8.078/90). O contato deverá ser feito através do canal “Contato” <a href="https://sflabs.com.br/contato/" target="_blank" rel="noopener noreferrer">(https://sflabs.com.br/contato/)</a>.</li>
                  <li><span className="politica--bold-text">Revogação de Permissões: </span>O usuário pode revogar seu consentimento sobre a coleta de informações do dispositivo mudando as configurações em seu dispositivo, se ele oferecer essas opções. Se o usuário assim o fizer, determinados serviços podem perder a funcionalidade completa.</li>
                  <li><span className="politica--bold-text">Exclusão de Conta: </span>Se por algum motivo o usuário quiser deixar de utilizar os serviços da SF Labs, basta entrar em contato conosco através do canal “Contato” <a href="https://sflabs.com.br/contato/" target="_blank" rel="noopener noreferrer">(https://sflabs.com.br/contato/)</a>. Se solicitar a exclusão da sua conta, o usuário terá até 30 dias para restaurá-la, antes de excluirmos as informações do usuário de nossos servidores permanentemente.</li>
                </ol>
              </div>
              <li className="politica__section-title politica--bold-text">Mudança das Políticas de Privacidade</li>
              <p className="politica__paragraph">De acordo com os Termos de Uso, esta Política de Privacidade poderá ser modificada, seja pela inclusão de novas funcionalidades, seja por exigência legal ou judicial. Quando isso ocorrer, enviaremos um e-mail para o endereço eletrônico cadastrado com a notificação de mudança e o inteiro teor das novas Políticas de Privacidade.</p>
              <li className="politica__section-title politica--bold-text">O aplicativo “Horus Leituras” são mantidos por SF Labs Indústria, Comércio e Serviços de Tecnologia LTDA , pessoa jurídica de direito privado com sede em Rua Alfredo Lopes 1717, cj. E8, São Carlos - SP, inscrita no CNPJ sob o nº 27.597.315/0001-14.</li>
            </ol>
          </div>
        </div>
      </div>
    )
  }
}

