import React from "react"
import DefaultLayout from "../layout/default.layout"
import PoliticaContainer from "../containers/politica-horus/politica-horus.container"

import { Helmet } from 'react-helmet'

 const PoliticaPage = () => (
  <DefaultLayout>
    <Helmet>
      <title>Horus | Gerenciamento de Barragens</title>
    </Helmet>
    <PoliticaContainer></PoliticaContainer>
  </DefaultLayout>
)

export default PoliticaPage